<template>
	<!--    todo rename, margin -->
	<div class="basket-item">
		<v-row>
			<v-col cols="12" md="8" class="d-flex flex-column flex-md-row align-center px-md-8">
				<div class="basket-item__img">
					<router-link :to="to"><v-img contain :src="product.PREVIEW_PICTURE" :alt="product.NAME"/></router-link>
				</div>
				<!--        <img class="basket-item-item__box-img mx-auto d-block d-sm-none" :src=product.imgMobile alt="Box">-->
				<div class="mt-8 mt-md-0 ml-md-8 text-center text-md-start">
					<router-link :to="to"><h4 v-html="nameHtml"></h4></router-link>
					<div v-html="subnameHtml"></div>

					<div v-if="offer" class="basket-item__offer">{{offer.SHORT || offer.NAME}}</div>

					<div v-if="content" class="basket-item__content">
						<div v-for="(flavour, index) of content" :key="'flavour'+index" class="basket-item__content-item">
							<v-img :src="flavour.PREVIEW_PICTURE"
								   :alt="flavour.clearName"
								   :title="flavour.clearName"
								   class="image"/>
						</div>
					</div>

				</div>
			</v-col>
			<v-col cols="12" md="4" class="d-md-flex justify-md-end align-md-center px-md-8">
				<div class="basket-item__controls text-center text-md-right d-md-flex justify-md-end align-md-center">
					<h4 class="basket-item__price mt-4 mt-md-0 d-block d-md-none">{{ subtotal | nf(2) }} €</h4>
					<counter v-model="item.AMOUNT"
							 class="mx-auto mx-md-0 mt-4 mt-md-0"
							 @change="$emit('change', item)"/>

					<h4 class="basket-item__price mt-4 mt-md-0 d-none d-md-block">{{ subtotal | nf(2) }} €</h4>
					<!--          todo проверить-->
					<button class="basket-item__delete mt-4 mt-md-0">
						<!--            todo v-img, svgicon-->
						<img class="d-none d-md-block" src="@/assets/Basket/delete.svg" alt="Delete" @click="onDel">
						<div class="d-block d-md-none size1" @click="onDel">Delete</div>
					</button>
				</div>
			</v-col>
		</v-row>

		<divider class="mt-10" />

		<v-dialog
				v-model="isDeleting"
				:persistent="isDeletingConfirmed"
				:content-class="'popup'"
				max-width="400px">
			<v-card class="glass">

				<v-card-title class="pt-8 justify-center">
					<div class="h5">{{page.texts.BASKET_WANT_DEL.NAME}}<!--Do you want to delete this product?--></div>
				</v-card-title>
				<v-card-text class="text-center">
					<div v-if="deletingItem" class="h5">{{ deletingItem.NAME }}</div>
				</v-card-text>
				<v-card-actions class="d-flex justify-space-between">
					<BtnText compact @click="isDeleting=false" :disabled="isDeletingConfirmed" :label="labelNo"/>
					<BtnText compact :loading="isDeletingConfirmed" @click="onDelConfirmed" :label="labelYes"/>
				</v-card-actions>

			</v-card>
		</v-dialog>
	</div>

</template>

<script>
	import Divider from "../../components/divider";
	import Counter from "../../components/counter";
	import BtnText from "../../components/btn-text";

	export default {
		name: "basket-item",
		components: {
			BtnText,
			Divider,
			Counter
		},
		props: {
			item: Object
		},
		data() {
			return {
				isDeleting: false,
				isDeletingConfirmed: false,
				deletingItem: null,
			}
		},
		computed: {
			product() {
				return this.item.product;
			},
			subtotal() {
				return Number(this.product.PRICE) * Number(this.item.AMOUNT);
			},
			nameHtml() {
				const item = this.product;
				//return item.name.substr(0, 1).toUpperCase() + item.name.substr(1).toLowerCase();
				let name = item.NAME;
				name = name.replace(/(^.+?)(in )?(dark|milk).+$/is, "$1");
				name = name.replace(/(^.+?)filled with .+$/is, "$1");
				name = name.replace(/(^.+?)\d+ g$/is, "$1");
				name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1");
				/*if ( name.match(/(^.+?)(\d+ pcs)$/is) ) {
					name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1<br>$2");
					if ( item.WEIGHT ) name += " / "+item.WEIGHT + " g";
				}
				else if ( item.WEIGHT ) name += "<br>"+item.WEIGHT + " g";*/

				return name;
			},
			subnameHtml() {
				const item = this.product;
				if (item.SUBNAME) return item.SUBNAME;
				//return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
				let choco = null;
				if (item.NAME.match(/.+?filled with .+$/)) {
					choco = item.NAME.replace(/.+?(filled with.+$)/is, "$1");
					choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
				} else if (item.NAME.match(/.+?(in )?(dark|milk).+$/)) {
					choco = item.NAME.replace(/.+?((in )?(dark|milk).+$)/is, "$1");
					choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
				} else if (item.NAME.match(/\d+ g$/)) {
					// todo make this related to item.WEIGHT
					choco = item.NAME.replace(/.+?(\d+ g$)/is, "$1");
				} else if (item.NAME.match(/\d+ pcs$/)) {
					choco = item.NAME.replace(/.+?(\d+ pcs$)/is, "$1");
					if (item.WEIGHT) choco += " / " + item.WEIGHT + " g";
				}

				return choco;
			},
			offer() {
				return this.item.offer;
			},
			content() {
				if ( !this.item.BASKET_PROPS ) return null;
				const json = this.item.BASKET_PROPS?.find(el=>el?.NAME === "JSON");
				if ( !json ) return null;
				const ids = JSON.parse(json.VALUE);
				return ids?.map(id=>this.page.flavours?.find(f=>f.ID===id)).map(f=>({
					...f,
					clearName: f.NAME.replace(/<br\\?>/, " ")
				}));
			},
			page() {
				return this.$store.state.page.BasketController;
			},
			labelYes() {
				return this.page.texts?.BASKET_YES?.NAME;
			},
			labelNo() {
				return this.page.texts?.BASKET_NO?.NAME;
			},
			categoryCode() {
				const cat = this.$store.state.common.sections.find(el => el.ID === this.product.IBLOCK_SECTION_ID);
				return cat?.CODE;
			},
			to() {
				return {
					name: 'product',
					params: {
						sectionCode: this.categoryCode,
						code: this.product.CODE
					}
				};
			},
		},
		methods: {
			add: function () {
				this.item.counter++;
			},
			sub: function () {
				if (this.item.counter > 0) this.item.counter--;
			},
			onDelConfirmed() {
				this.isDeletingConfirmed = true;

				this.$store.dispatch('post', {
					action: 'BasketDelController',
					params: {
						productId: this.deletingItem.ID
					}
				}).then((result) => {
					this.$store.commit('setSession', result);
					this.isDeleting = false;
					this.isDeletingConfirmed = false;
					this.deletingItem = null;
				}).catch((error) => {
					//console.log("ERROR: ", error);
					// this.error = error;
					this.error = "Error. Please try again"
				}).finally(() => {
					this.isDeletingConfirmed = false;
				});
			},
			onDel() {
				this.isDeleting = true;
				this.deletingItem = this.product;
				this.deletingItem.ID = this.item.PRODUCT_ID;
			},
		}
	}
</script>

<style lang="scss">
	.basket-item {
		width: 100%;
		padding: 16px 0;

		&__img {
			display: flex;
			justify-content: center;
			width: 280px;

			@include up($md) {
				width: 132px;
			}

			.v-image {
				max-width: 280px;
				max-height: 280px;

				@include up($md) {
					max-width: 132px;
					max-height: 132px;
				}
			}
		}

		&__counter {
			width: 100%;
			max-width: 90px;
			height: 41px;
			border: 2px solid $black-50;
		}

		&__controls {
			width: 100%;

			@include up($md) {
				max-width: 320px;
			}
		}

		&__price {
			color: $black !important;
			text-align: center;

			@include up($md) {
				width: 180px;
				text-align: right;
				padding-right: 32px;
			}
		}

		&__delete {

			@include up($md) {
				width: 32px;
				height: 32px;
			}

			.size1 {
				color: $black-100;
			}

			img {
				width: 100%;
				height: 100%;
			}
		}

		&__content {
			margin-top: 4px;
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;

			&-item {
				margin-left: 8px;
				margin-top: 8px;

				&:first-child {
					margin-left: 0;
				}
			}

			.name {

			}
			.image {
				width: 24px;
				height: 24px;
			}
		}

		h3, h4 {
			color: $primary-500;
		}
	}
</style>