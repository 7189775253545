<template>
	<v-btn class="btn-text"
		   text
		   :loading="loading"
		   @click="$emit('click')">
		{{label}}
	</v-btn>
</template>

<script>
    export default {
        name: "btn-text",
        props: ["label", "loading"],
    }

</script>

<style lang="scss">
	.btn-text {
		display: inline-block;
		height: 32px !important;
		color: $golden !important;
		font-size: $fs-1 !important;
		line-height: $lh-1 !important;
		letter-spacing: $ls-1 !important;
		text-transform: unset !important;
		box-sizing: border-box;
		padding: 0 28px !important;
		border-radius: 0 !important;

		@include transition();

		&:hover,&:active {
			border-color: transparent;
			background: $golden !important;
			color: $white !important;
		}

		&:disabled {
			border: 2px solid $black-50;
			background: transparent;
			color: $black-50;
		}

		/*&.large {
			height: 50px !important;
			font-size: $fs-h5 !important;
			line-height: $lh-h5 !important;
			letter-spacing: $ls-h5 !important;
		}*/
		&.block {
			display: block;
		}
	}


</style>